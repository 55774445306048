import React, { useEffect, useState } from "react";
import BenefitService, { ICoupon, IEvents } from "../../service/BenefitService";
import EventItem from "./EventItem";
import CouponItem from "./CouponItem";

const BenefitList = () => {
  const [events, setEvents] = useState<IEvents>();
  const [coupons, setCoupons] = useState<ICoupon[]>();
  const [eventState, setEventState] = useState<string>("active");
  const [couponState, setCouponState] = useState<string>("");

  const fetchEvents = () => {
    BenefitService.getEvents()
      .then((res) => {
        setEvents(res);
      })
      .catch((e) => {
        console.error("fetchEvents error:: ", e);
      });
  };

  const fetchCoupons = () => {
    BenefitService.getCoupons()
      .then((res) => {
        setCoupons(res);
      })
      .catch((e) => {
        console.error("fetchCoupons error:: ", e);
      });
  };

  useEffect(() => {
    fetchEvents();
    fetchCoupons();
  }, []);

  const showEvents = () => {
    setCouponState("");
    setEventState("active");
  };

  const showCoupons = () => {
    setEventState("");
    setCouponState("active");
  };

  return (
    <section className="benefit-list">
      <ul className="tab">
        <button className={`tablinks ${eventState}`} onClick={showEvents}>
          이벤트
        </button>
        <button className={`tablinks ${couponState}`} onClick={showCoupons}>
          쿠폰
        </button>
      </ul>
      <div
        id="event"
        className="tabcontent"
        style={{ display: `${eventState === "active" ? `block` : `none`}` }}>
        <ul id="tab-eventul">
          {events &&
            events.open.map((item) => {
              return <EventItem key={item.id} event={item} />;
            })}
        </ul>
      </div>
      <div
        id="coupon"
        className="tabcontent"
        style={{ display: `${couponState === "active" ? `block` : `none`}` }}>
        <ul id="tab-couponul">
          {coupons &&
            coupons.map((item) => {
              return <CouponItem key={item.id} coupon={item} />;
            })}
        </ul>
      </div>
    </section>
  );
};

export default BenefitList;
