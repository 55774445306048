import React, { useState } from "react";
import { history } from "../../index";
import { corpSocarSite, jobsSite } from "../../utils/constant";

const Footer = () => {
  const path = history.location.pathname;
  const [btnSelect, btnSetSelect] = useState(false);
  const toggleBtnClass = (e: any) => {
    btnSetSelect(!btnSelect);
  };
  const isCompanyPath = path === "/company";

  return (
    <>
      {!isCompanyPath && (
        <>
          <section className="section" id="sec-more">
            <ul className="">
              <li className="share mv">
                <a href="/service">카셰어링 쏘카</a>
              </li>
              <li className="business mv">
                <a
                  href="https://business.socar.kr"
                  target="_blank"
                  rel="noopener noreferrer">
                  쏘카 비즈니스
                </a>
              </li>
              <li className="plan mv">
                <a
                  href="https://plan.socar.kr"
                  target="_blank"
                  rel="noopener noreferrer">
                  쏘카 플랜
                </a>
              </li>
              <li className="pairing mv">
                <a
                  href="https://socar-mkt.socar.kr/socarpairing/"
                  target="_blank"
                  rel="noopener noreferrer">
                  쏘카 페어링
                </a>
              </li>
              <li className="use arrow">
                <a href="/guide">쏘카 이용 안내</a>
              </li>
              <li className="fee arrow">
                <a href="/fare">요금 보기</a>
              </li>
              <li className="event arrow">
                <a href="/benefit">혜택 확인하기</a>
              </li>
            </ul>
          </section>
          <footer>
            <section className="sec-wrap">
              <ul id="sns">
                <li>
                  <a
                    href="https://www.instagram.com/socar.kr/"
                    className="insta"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="" src="/images/i-instagram.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/socar.kr"
                    className="fb"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="" src="/images/i-facebook.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCsNtu0KJ3SJfs1zcQj8xEYQ"
                    className="yt"
                    target="_blank"
                    rel="noopener noreferrer">
                    <img className="" src="/images/i-youtube.svg" alt="" />
                  </a>
                </li>
              </ul>
              <ul id="customer">
                <li>
                  <a
                    href={corpSocarSite}
                    target="_blank"
                    rel="noopener noreferrer">
                    회사소개
                  </a>
                </li>
                <li>
                  <a href={jobsSite} target="_blank" rel="noopener noreferrer">
                    인재채용
                  </a>
                </li>
                <li>
                  <a href="/terms">이용약관</a>
                </li>
                <li>
                  <a
                    href="https://socar-docs.zendesk.com/hc/ko/articles/360048398254"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="various"
                    style={{ color: "#FFF" }}>
                    개인정보처리방침
                  </a>
                </li>
                <li>
                  <a
                    href="https://socar-docs.zendesk.com/hc/ko/articles/360049150593"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="various"
                    style={{ color: "#FFF" }}>
                    위치정보 이용약관
                  </a>
                </li>
                <li>
                  <a
                    href="https://socar-docs.zendesk.com/hc/ko/articles/360052593593"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="various"
                    style={{ color: "#FFF" }}>
                    차량손해면책제도
                  </a>
                </li>
                <li>
                  <a
                    href="https://socarhelp.zendesk.com/hc/ko"
                    target="_blank"
                    rel="noopener noreferrer">
                    고객센터
                  </a>
                </li>
                <li>
                  <a href="#partnership-inquiry" className="various">
                    제휴 문의
                  </a>
                </li>
              </ul>
              <div id="pop-inquiry">
                <section className="pop-cont" id="partnership-inquiry">
                  <h3>제휴 문의</h3>
                  <p>
                    제휴 문의는{" "}
                    <a href="mailto:hello@socar.kr">hello@socar.kr</a>로
                    보내주세요. 아래의 내용을 포함하면 더 정확한 답변을 받으실
                    수 있습니다.
                  </p>
                  <ul>
                    <li>
                      제휴 구분(법인 제휴, 사업 제휴, 마케팅 제휴, 광고 제휴 등)
                    </li>
                    <li>제안 배경 / 세부 제안 내용 / 제휴 기대 효과</li>
                    <li>(필요시) 파일 첨부</li>
                  </ul>
                </section>
                <section className="pop-cont" id="zone-inquiry">
                  <h3>쏘카존 개설 문의</h3>
                  <p>
                    쏘카존 개설 문의는{" "}
                    <a href="mailto:infra@socar.kr">infra@socar.kr</a>로
                    보내주세요. 아래의 내용을 포함하면 더 정확한 답변을 받으실
                    수 있습니다.
                  </p>
                  <ul>
                    <li>요청 지역(건물명, 주차장명, 쏘카존명 중 기입)</li>
                    <li>상세 주소(시, 군, 구 포함 상세주소 기입)</li>
                    <li>요청 사유</li>
                  </ul>
                </section>
              </div>
              <ul id="ct">
                <li>주소. 제주특별자치도 제주시 연미길 42 (오라삼동)</li>
                <li>전화. 1661-3315</li>
                <li>팩스. 02-6969-9333</li>
              </ul>
              <div id="info">
                <p>
                  (주)쏘카 통신판매업{" "}
                  <a
                    href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=6168190529&apv_perm_no="
                    target="_blank"
                    rel="noopener noreferrer">
                    사업자정보 확인
                  </a>
                </p>
                <p>
                  신고: 제 2019-제주오라-3호, 사업자등록번호: 616-81-90529,
                  대표자: 박재욱
                </p>
              </div>
              <address>
                © {new Date().getFullYear()} SOCAR All rights reserved.
              </address>
            </section>
            <div className="pop-cont" id="applink">
              <h3>모바일 기기에서 활용해주세요</h3>
              <a href="https://go.socar.kr/sdhv3ZE4r5">
                <img
                  id=""
                  className="smooth-sl"
                  src="../images/i-appicon.svg"
                  alt=""
                />
                <span className="">쏘카앱 다운로드</span>
              </a>
            </div>
          </footer>
        </>
      )}
      {isCompanyPath && (
        <footer id="company-footer">
          <section className="sec-wrap">
            <ul id="sns">
              <li>
                <a
                  href="https://www.instagram.com/socar.kr/"
                  className="insta"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="a11y-hidden">인스타그램</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/socar.kr"
                  className="fb"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="a11y-hidden">페이스북</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCsNtu0KJ3SJfs1zcQj8xEYQ"
                  className="yt"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="a11y-hidden">유튜브</span>
                </a>
              </li>
            </ul>
            <div id="customer">
              <ul className="customer-01">
                <li>
                  <a
                    href="https://socarhelp.zendesk.com/hc/ko"
                    target="_blank"
                    rel="noopener noreferrer">
                    고객센터
                  </a>
                </li>
                <li>
                  <a href="/terms">이용약관</a>
                </li>
                <li>
                  <a
                    href="https://socar-docs.zendesk.com/hc/ko/articles/360048398254"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="various">
                    <strong>개인정보처리방침</strong>
                  </a>
                </li>
              </ul>
              <ul className="customer-02">
                <li>
                  <a
                    href="https://socar-docs.zendesk.com/hc/ko/articles/360049150593"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="various">
                    <strong>위치정보 이용약관</strong>
                  </a>
                </li>
              </ul>
              <ul className="customer-03">
                <li>
                  <a href="#partnership-inquiry" className="various">
                    제휴 문의
                  </a>
                </li>
              </ul>
            </div>
            <div id="pop-inquiry">
              <section className="pop-cont" id="partnership-inquiry">
                <h3>제휴 문의</h3>
                <p>
                  제휴 문의는 <a href="mailto:hello@socar.kr">hello@socar.kr</a>
                  로 보내주세요. 아래의 내용을 포함하면 더 정확한 답변을 받으실
                  수 있습니다.
                </p>
                <ul>
                  <li>
                    제휴 구분(법인 제휴, 사업 제휴, 마케팅 제휴, 광고 제휴 등)
                  </li>
                  <li>제안 배경 / 세부 제안 내용 / 제휴 기대 효과</li>
                  <li>(필요시) 파일 첨부</li>
                </ul>
              </section>
              <section className="pop-cont" id="zone-inquiry">
                <h3>쏘카존 개설 문의</h3>
                <p>
                  쏘카존 개설 문의는{" "}
                  <a href="mailto:infra@socar.kr">infra@socar.kr</a>로
                  보내주세요. 아래의 내용을 포함하면 더 정확한 답변을 받으실 수
                  있습니다.
                </p>
                <ul>
                  <li>요청 지역(건물명, 주차장명, 쏘카존명 중 기입)</li>
                  <li>상세 주소(시, 군, 구 포함 상세주소 기입)</li>
                  <li>요청 사유</li>
                </ul>
              </section>
            </div>
            <div className="pc-view">
              <ul className="info-left">
                <li>
                  (주)쏘카 통신판매업{" "}
                  <a
                    href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=6168190529&apv_perm_no="
                    target="_blank"
                    rel="noopener noreferrer">
                    사업자정보 확인
                  </a>
                </li>
                <li>신고: 제 2019-제주오라-3호</li>
                <li>사업자등록번호: 616-81-90529 대표자: 박재욱</li>
              </ul>
              <ul className="info-right">
                <li>
                  주소: 제주특별자치도 제주시 연미길 42 | 서울특별시 성동구
                  왕십리로 83-21 아크로서울포레스트 디타워 3~5층
                </li>
                <li>전화: 1661-3315 팩스: 02-6969-9333</li>
              </ul>
              <div className="clear-fix"></div>
            </div>
            <div className={btnSelect ? "mo-view show" : "mo-view"}>
              <button onClick={toggleBtnClass}>
                (주) 쏘카 <span>자세히보기</span>
              </button>
              <ul className="info">
                <li>
                  통신판매업{" "}
                  <a
                    href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=6168190529&apv_perm_no="
                    target="_blank"
                    rel="noopener noreferrer">
                    사업자정보 확인
                  </a>{" "}
                  신고: 제 2019-제주오라-3호{" "}
                </li>
                <li>사업자등록번호: 616-81-90529, 대표자: 박재욱</li>
                <li>Tel: 1661-3315, Fax: 02-6969-9333</li>
                <li>주소: 제주특별자치도 제주시 연미길 42 | 서울특별시 </li>
                <li>성동구 왕십리로 83-21 아크로서울포레스트 디타워 3~5층</li>
              </ul>
            </div>
            <p className="copy">
              © {new Date().getFullYear()} SOCAR ALL rights reserved.
            </p>
          </section>
          <div className="pop-cont" id="applink">
            <h3>모바일 기기에서 활용해주세요</h3>
            <a href="https://go.socar.kr/sdhv3ZE4r5">
              <img
                id=""
                className="smooth-sl"
                src="../images/i-appicon.svg"
                alt=""
              />
              <span className="">쏘카앱 다운로드</span>
            </a>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
