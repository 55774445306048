export const LEGACY_API_URL = process.env.LEGACY_API_HOST;
const TIME_OUT = 10000;

class RequestError extends Error {
  private code: string;
  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
}

function createHeaders() {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  return headers;
}

export const post = (path: string, data: object) => {
  return Promise.race([
    fetch(`${LEGACY_API_URL}${path}`, {
      method: "post",
      headers: createHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok && res.status !== 500)
          throw new RequestError(
            `[${res.status}] - ERROR`,
            "서버와 통신이 원활하지 않습니다"
          );
        return res.json();
      })
      .then((json) => {
        if (json.error) throw new RequestError(json.error, json.message);
        return json;
      }),
    new Promise((_, reject) =>
      setTimeout(() => {
        reject(
          new RequestError("TIME_OUT_ERROR", "서버와 통신이 원활하지 않습니다")
        );
      }, TIME_OUT)
    ),
  ]);
};

export const get = (path: string) => {
  return Promise.race([
    fetch(`${LEGACY_API_URL}${path}`, {
      method: "get",
      headers: createHeaders(),
    })
      .then((res) => {
        if (!res.ok && res.status !== 500)
          throw new RequestError(
            `[${res.status}] - ERROR`,
            "서버와 통신이 원활하지 않습니다"
          );
        return res.json();
      })
      .then((json) => {
        if (json.retCode !== "1")
          throw new RequestError(json.error, json.message);
        return json.result;
      }),
    new Promise((_, reject) =>
      setTimeout(() => {
        reject(
          new RequestError("TIME_OUT_ERROR", "서버와 통신이 원활하지 않습니다")
        );
      }, TIME_OUT)
    ),
  ]);
};
