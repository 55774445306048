import { get } from "./Api";

export default {
  getFaqsWithKeyword(
    limit: string,
    keyword: string,
    pageNumber: number
  ): Promise<IFaqs> {
    return get(
      `/cs/faq?has_contents=true&limit=${limit}&mode=search&search_text=${keyword}&page=${pageNumber}`
    );
  },

  getFaqsWithCategoryId(
    limit: string,
    categoryId: string,
    pageNumber: number
  ): Promise<IFaqs> {
    return get(
      `/cs/faq?has_contents=true&limit=${limit}&category_id=${categoryId}&page=${pageNumber}`
    );
  },
};

export interface IFaqs {
  current_page: number;
  data: IFaq[];
  total_count: number;
  total_page: number;
}

export interface IFaq {
  id: string; // "110"
  title: string; // "쏘카존에 주차할 자리가 없어용"
  content: string; // "<div>주차 만차시에는 ...</div>"
  category: string; // "0"
  cs_category: string; // null or "A"
}
