import React, { useEffect, useState } from "react";

const Body: React.FC = ({ children }) => {
  const [minHeight, setMinHeight] = useState<string>("100vh");

  useEffect(() => {
    const footer = document.getElementsByTagName("footer")[0];
    if (footer) setMinHeight(`calc(100vh - ${footer.offsetHeight}px)`);
  }, []);

  return (
    <div id="body" style={{ minHeight }}>
      {children}
    </div>
  );
};

export default Body;
