import React from "react";
import { IEvent } from "../../service/BenefitService";

const EventItem = ({ event }: { event: IEvent }) => {
  return (
    <li className="">
      <a href="https://go.socar.kr/2MYVF012r5" className="applink">
        <h4>{event.list_name}</h4>
        <h5>{event.list_time}</h5>
        <h5>{event.list_comment}</h5>
      </a>
    </li>
  );
};

export default EventItem;
