import React from "react";
import { ICoupon } from "../../service/BenefitService";

const CouponItem = ({ coupon }: { coupon: ICoupon }) => {
  return (
    <li>
      <a href="https://go.socar.kr/k4IVaAP2r5" className="applink">
        <h4>{coupon.title_comment}</h4>
        <h5>{`${coupon.activate_start_at}~${coupon.activate_end_at}`}</h5>
        <h5 dangerouslySetInnerHTML={{ __html: coupon.condition_comment }}></h5>
      </a>
    </li>
  );
};

export default CouponItem;
