import React, { useEffect, useState } from "react";
import FaqService, { IFaqs } from "../service/FaqService";
import FaqItem from "../components/faq/FaqItem";

interface ICategory {
  categoryId: string;
  title: string;
  active: boolean;
}

const defaultCategories: ICategory[] = [
  { categoryId: "0", title: "자주묻는 질문", active: false },
  { categoryId: "1", title: "예약/결제문의", active: false },
  { categoryId: "2", title: "가입문의", active: false },
  { categoryId: "3", title: "차량이용/사고", active: false },
  { categoryId: "4", title: "프로모션/쿠폰", active: false },
  { categoryId: "5", title: "법인/단체", active: false },
  { categoryId: "6", title: "탈퇴", active: false },
  { categoryId: "7", title: "기타", active: false },
];

const Faq = () => {
  enum SearchType {
    KEYWORD,
    CATEGORY,
  }

  const [searchType, setSearchType] = useState<SearchType>(SearchType.CATEGORY);
  const [keyword, setKeyword] = useState<string>("");
  const [categoryId, setCategoryId] = useState<string>("0");
  const [categories, setCategories] = useState<ICategory[]>(defaultCategories);
  const [faqs, setFaqs] = useState<IFaqs>();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const Categories = () => {
    return (
      <div id="faq-cate">
        {categories.map((category) => {
          return (
            <button
              key={category.categoryId}
              className={`${category.active ? "act" : ""}`}
              onClick={() => handleCategory(category.categoryId)}>
              {category.title}
            </button>
          );
        })}
      </div>
    );
  };

  const handleCategory = (categoryId: string) => {
    fetchFaqsWithCategoryId({ categoryId, page: 0 });
    updateCategories(categoryId);
    setCurrentPage(0);
    setCategoryId(categoryId);
  };

  const updateCategories = (categoryId: string) => {
    const updateCategories = categories
      .map((category) => {
        return { ...category, active: false };
      })
      .map((category) => {
        if (category.categoryId === categoryId) {
          return { ...category, active: true };
        }
        return category;
      });
    setCategories(updateCategories);
  };

  const handleKeyword = () => {
    fetchFaqsWithKeyword({ page: 0 });
    setCurrentPage(0);
  };

  const fetchFaqs = () => {
    if (searchType === SearchType.KEYWORD) {
      fetchFaqsWithKeyword({ page: currentPage + 1 });
    } else if (searchType === SearchType.CATEGORY) {
      fetchFaqsWithCategoryId({ categoryId, page: currentPage + 1 });
    }
  };

  const fetchFaqsWithKeyword = ({ page }: { page: number }) => {
    setSearchType(SearchType.KEYWORD);
    setCurrentPage(currentPage + 1);
    FaqService.getFaqsWithKeyword("10", keyword, page)
      .then((res) => {
        if (faqs && page > 0) {
          const data = faqs.data.concat(res.data);
          setFaqs({
            current_page: res.current_page,
            data: data,
            total_count: res.total_count,
            total_page: res.total_page,
          });
        } else {
          setFaqs(res);
        }
      })
      .catch((e) => {
        console.error("fetchFaqsWithKeyword error:: ", e);
      });
  };

  const fetchFaqsWithCategoryId = ({
    categoryId,
    page,
  }: {
    categoryId: string;
    page: number;
  }) => {
    setSearchType(SearchType.CATEGORY);
    setCurrentPage(currentPage + 1);
    FaqService.getFaqsWithCategoryId("10", categoryId, page)
      .then((res) => {
        if (faqs && page > 0) {
          const data = faqs.data.concat(res.data);
          setFaqs({
            current_page: res.current_page,
            data: data,
            total_count: res.total_count,
            total_page: res.total_page,
          });
        } else {
          setFaqs(res);
        }
      })
      .catch((e) => {
        console.error("fetchFaqsWithCategory error:: ", e);
      });
  };

  const MoreButton = () => {
    if (!faqs) return null;
    if (faqs.total_page === 0) return null;
    if (faqs.total_count < 10) return null;
    if (faqs.data.length === 0) return null;
    return (
      <div>
        <div onClick={() => fetchFaqs()} id="more_btn">
          더보기
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchFaqsWithCategoryId({ categoryId: "0", page: 0 });
  }, []);

  return (
    <>
      <section className="head" id="cs-head">
        <div className="cent">
          <h1 className="">무엇을 도와 드릴까요?</h1>
        </div>
      </section>

      <section className="content" id="cs-cont">
        <div className="wrap">
          <section className="">
            <h2 className="sub-title">
              쏘카 이용 중 어떤 부분이 궁금하신가요?
            </h2>

            <div id="faq-search">
              <div>
                <input
                  type="text"
                  className="form-control"
                  id="input_search"
                  placeholder="search"
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <button className="btn-primary" onClick={() => handleKeyword()}>
                  검색
                </button>
              </div>
            </div>

            <Categories />

            <ul id="cs-acc">
              {faqs &&
                faqs.data.map((item, index) => {
                  return <FaqItem key={item.id + index} faq={item} />;
                })}
            </ul>

            <MoreButton />
          </section>

          <section className="banner-01" id="cs-call">
            <h2 className="cent">
              예약 및 이용 관련 문의 &middot; 차량 고장 및 운행 중 긴급문의는
              <br />
              <span>쏘카 앱 내 고객센터</span>를 이용해주세요.
            </h2>
            <div id="blue" className="mvright"></div>
          </section>

          <section className="st-02" id="cs-link">
            <ul>
              <li>
                <h3 className="mv etc">
                  서류 제출 및 건의사항은
                  <br /> 아래 버튼을 눌러주세요.
                </h3>
                <a
                  href="https://go.socar.kr/EUmfv372r5"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="applink btn btnu btna mv3 temp">
                  제출/신청
                </a>
              </li>
              <li>
                <h3 className="mv">쏘카를 이용하시는데 불편함이 있으셨나요?</h3>
                <p className="mv">
                  이용 불편 및 문의사항 고객센터 지원센터에 남겨주시면
                  답변드리겠습니다.
                </p>
                <a
                  href="#partnership-inquiry"
                  className="various btn btnu btna mv3 temp">
                  제휴 문의
                </a>
                <a
                  href="https://modubiz.oopy.io/socar"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="various btn btnu btna mv3 right">
                  쏘카존 개설 문의
                </a>
              </li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default Faq;
