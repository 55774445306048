import React from "react";
import BenefitList from "../components/benefit/BenefitList";

const Benefit = () => {
  return (
    <>
      <section className="head" id="benefit-head">
        <div className="cent">
          <h1>쏘카 혜택 안내</h1>
          <h2>쏘카의 다양한 혜택을 놓치지 마세요.</h2>
        </div>
      </section>

      <section className="content" id="benefit-cont">
        <div className="wrap">
          <section className="sale">
            <h2 className="sub-title">기본 할인</h2>
            <ul>
              <li>친구 초대 시 1만 원 할인 쿠폰</li>
              <li>신규 쏘카존은 기본 15% 상시 할인</li>
            </ul>
          </section>

          <BenefitList />
        </div>
      </section>
    </>
  );
};

export default Benefit;
