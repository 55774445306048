import React, { useEffect, useState } from "react";
import { useMedia } from "use-media";

export const formatCurrency = (numberValue: number) => {
  if (!numberValue) return "0";
  const pattern = /(-?[0-9]+)([0-9]{3})/;
  let numString = numberValue.toString();
  while (pattern.test(numString)) {
    numString = numString.replace(pattern, "$1,$2");
  }
  return numString;
};

const Home = () => {
  // 회원수 자동증가를 위한 스크립트
  // 2024/08/05 기준 회원수
  const [mainVisualPath, setMainVisualPath] = useState("");
  const [mainVisualAlt, setMainVisualAlt] = useState("");
  const defaultCount = 10_005_173;
  const defaultDay = new Date("2024-08-05");
  const dailyIncrement = 3000;
  const now = new Date();
  const countOfDays =
    (now.getTime() - defaultDay.getTime()) / 1000 / 60 / 60 / 24;
  const memberCount = defaultCount + dailyIncrement * Math.floor(countOfDays);
  const isPC = useMedia({ minWidth: "769px" });

  useEffect(() => {
    if (isPC) {
      setMainVisualPath("/images/main_visual_fullscreen.jpg");
    } else {
      setMainVisualPath("/images/main_visual_mobile.jpg");
    }

    setTimeout(() => {
      setMainVisualAlt("쏘카를 설명하는 이미지");
    }, 200);
  }, [isPC]);

  return (
    <>
      <section className="section" id="intro">
        <div id="main-g" className="cent">
          <p id="f">
            차가 필요한
            <br />
            모든 순간, 쏘카
          </p>
        </div>
        <img
          src={`${mainVisualPath}`}
          alt={`${mainVisualAlt}`}
          className="main-visual"
        />
      </section>

      <section id="main-sec" className="">
        <section className="section" id="sec-usp">
          <div className="wrap">
            <section className="desc_service">
              <article>
                <h2 className="mv">24시간 언제나</h2>
                <p className="mv">
                  한밤중이나 주말에도 스마트폰으로
                  <br /> 시간에 구애받지 않고
                  <br /> 쏘카와 함께 이동하세요.
                </p>
              </article>
              <div>
                <img
                  alt="쏘카를 이용하는 사람"
                  src="/images/usp_a.jpg"
                  className="mv"
                />
              </div>
            </section>
            <section className="desc_service">
              <article>
                <h2 className="mv">원하는 곳에서</h2>
                <p className="mv">
                  내 주변 3분 거리 쏘카존에서
                  <br /> 혹은 내가 있는 곳으로 쏘카를 불러
                  <br /> 어디서나 편하게 출발하세요.
                </p>
              </article>
              <div>
                <img
                  alt="휴대폰을 보는 사람"
                  src="/images/usp_b.jpg"
                  className="mv"
                />
              </div>
            </section>
            <section className="desc_service">
              <article>
                <h2 className="mv">필요한 시간만큼</h2>
                <p className="mv">
                  간단한 장보기부터 주말여행까지
                  <br /> 10분 단위로 원하는 만큼만 이용하세요.
                </p>
              </article>
              <div>
                <img
                  alt="식자재와 쏘카차량"
                  src="/images/usp_c.jpg"
                  className="mv"
                />
              </div>
            </section>
            <section className="desc_service">
              <article>
                <h2 className="mv">다양한 차종을</h2>
                <p className="mv">
                  연인과의 드라이브부터 가족 캠핑까지
                  <br /> 상황에 맞게 50여 종의 차량을
                  <br /> 선택해서 이용하세요.
                </p>
              </article>
              <div>
                <img
                  alt="주차장에 있는 쏘카차량들"
                  src="/images/usp_d.jpg"
                  className="mv"
                />
              </div>
            </section>
          </div>
        </section>
        <section className="section" id="sec-fact">
          <div className="wrap">
            <div className="copys">
              <article className="cent">
                <span className="counter count">
                  {formatCurrency(memberCount)}
                </span>
                명의 회원이 쏘카와
                <br /> 함께하고 있습니다.
                <br /> 지금 바로 <span>쏘카 드라이브</span>를<br /> 시작하세요.
              </article>
            </div>
            <div id="blue" className="mvright"></div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Home;
