import React from "react";

const Fare = () => {
  return (
    <>
      <section className="head" id="fare-head">
        <div className="cent">
          <h1>쏘카 요금 안내</h1>
          <h2>쏘카의 이용 요금을 자세히 알아보세요.</h2>
        </div>
      </section>

      <section className="content" id="fare-cont">
        <div className="wrap">
          <section className="fare-info">
            <h2 className="sub-title">쏘카 서비스 요금</h2>
            <ul className="info-box">
              <li>
                <h3>탑승 전 결제</h3>
                <div>
                  <h6>대여요금</h6>
                  <p>시간당 차량을 대여하는 요금입니다.</p>
                </div>
                <div className="g">
                  <h6>보험료</h6>
                  <p>
                    사고 시 최대 자기부담금을 선택할 수 있으며 이에 따라
                    보험료가 상이합니다.
                  </p>
                </div>
              </li>
              <li>
                <h3>반납 시 결제</h3>
                <div className="">
                  <h6>주행료</h6>
                  <p>
                    주행거리에 따라 km당 주행요금이 부과됩니다. (하이패스 이용
                    시 주행요금과 합산 청구)
                  </p>
                </div>
              </li>
            </ul>
            <a
              href="https://socar-docs.zendesk.com/hc/ko/articles/360049151813"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btna btnu mv3">
              쏘카 요금표 자세히 보기
            </a>
          </section>

          <section className="fare-insurance">
            <h2 className="sub-title">쏘카 보험 안내</h2>
            <h6>
              쏘카는 이용 중 발생할 수 있는 사고로부터 회원님과 상대방의 피해를
              보장하기 위해 전 차량 자손, 대인, 대물 피해를 보상하는
              자동차종합보험에 가입하고 있습니다.
              <br />
              또한 쏘카 차량의 피해에 대해 회원님의 부담을 덜어드리기 위해
              자동차종합보험과는 별개로 쏘카 차량손해면책제도를 운영하고
              있습니다.
              <br />
              쏘카 차량 대여 계약 체결 시 차량손해면책 상품은 필수로 가입해야
              하며, 서비스별 면책금의 한도를 직접 선택하여 가입할 수 있습니다.
            </h6>
            <ul className="info-box">
              <li>
                <h3>고객 부담</h3>
                <div>
                  <h6>쏘카 수리비</h6>
                  <p>
                    차량 대여 계약 체결 시 선택한 면책 상품에 따라 자기부담금
                    이하 금액만큼만 부담
                  </p>
                </div>
              </li>
              <li>
                <h3>쏘카 부담</h3>
                <div className="g">
                  <h6>대인 : 무한</h6>
                  <p>사고 상대방의 인명 피해</p>
                </div>
                <div className="g">
                  <h6>대물 : 1억원 한도</h6>
                  <p>사고 상대방의 물적 피해</p>
                </div>
                <div className="g">
                  <h6>자손 : 1천5백만원 한도</h6>
                  <p>운전자 자신이 다친 부분</p>
                </div>
              </li>
            </ul>
            <div className="insurance-etc">
              <h3>자동차 종합보험</h3>
              <p>
                쏘카의 모든 차량은 자손/대인/대물의 피해를 보상하는
                자동차종합보험에 가입되어 있습니다.
              </p>
              <hr />
              <h3>차량손해면책제도</h3>
              <p>
                • 차량 대여 계약 체결 시 선택한 면책 상품에 따라 사고 발생 시
                쏘카 차량 수리 비용에 대해 회원님이 부담해야 할 최대
                금액(자기부담금)이 정해집니다.
                <br />
                • 차량 수리 비용이 자기부담금을 초과하더라도 선택한 면책
                상품에서 정한 자기부담금만큼만 지불하시면 됩니다. (자기부담금
                미만일 경우 실비 정산)
                <br />
                • 차량손해면책제도의 보장 범위는 차량 수리 비용 및
                휴차보상료이며, 긴급출동, 견인, 구난 등 현장 처리 비용은 보장
                범위에서 제외됩니다.
                <br />• 면책 상품 및 면책 보험료는 쏘카에서 제공하는 서비스,
                지역, 차종, 대여 기간 및 회원 특성 등에 따라 상이할 수 있습니다.
                대여 계약 체결 시 꼭 확인해 주세요.
              </p>
              <ul className="tiny-info">
                <li>
                  차량손해면책 상품에 가입이 되어있더라도 차량손해면책제도
                  이용약관 제5조 금지조항에 해당하는 행위가 적발된 경우
                  차량손해면책제도 적용이 불가하며, 사고처리비용 및 휴차보상료
                  전액과 손해배상 비용이 청구됩니다. 또한 법적 고발 및 이용 불가
                  조치가 행해질 수 있으니, 이 점 유의해주시기 바랍니다.
                </li>
                <li>
                  쏘카가 서비스하는 자동차의 이용 및 보험에 관한 사항은 쏘카
                  이용약관 및 자동차대여약관, 차량손해면책제도 이용약관에
                  따릅니다. 예약 전에 꼭 숙지해 주세요.
                </li>
              </ul>
              <div className="link-info">
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360049150553"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btna btnu mv3">
                  자동차대여약관 확인하기
                </a>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360052593593"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btna btnu mv3">
                  쏘카 차량손해면책제도 이용약관 확인하기
                </a>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360048399814"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btna btnu mv3">
                  쏘카 휴차보상료 안내표 보기
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Fare;
