import React from "react";

const Terms = () => {
  return (
    <>
      <section className="head head-none" id="terms-head">
        <h1 className="page-title">이용약관</h1>
      </section>

      <section className="content" id="terms-cont">
        <div className="wrap">
          <section>
            <h3 style={{ marginTop: "0" }}>쏘카</h3>
            <ul className="zero">
              <li>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360048397194"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="smooth-sl btna">
                  쏘카 이용약관
                </a>
              </li>
              <li>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360048398254"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="smooth-sl btna">
                  개인정보처리방침
                </a>
              </li>
              <li>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360049150553"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="smooth-sl btna">
                  자동차대여약관
                </a>
              </li>
              <li>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360049150593"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="smooth-sl btna">
                  위치정보 이용약관
                </a>
              </li>
              <li>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360052593593"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="smooth-sl btna">
                  차량손해면책제도
                </a>
              </li>
              <li>
                <a
                  href="https://socar-docs.zendesk.com/hc/ko/articles/360048521674"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="smooth-sl btna">
                  전자금융거래 이용약관
                </a>
              </li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default Terms;
