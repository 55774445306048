import { IFaq } from "../../service/FaqService";
import React from "react";

const FaqItem = ({ faq }: { faq: IFaq }) => {
  return (
    <li className="">
      <div className="list-title">
        <a href="#" className="accordion">
          <h3>{faq.title}</h3>
          <span className="cth acco-icon smooth">
            <i></i>
            <i></i>
          </span>
        </a>
        <div className="panel">
          <p className="" dangerouslySetInnerHTML={{ __html: faq.content }}></p>
        </div>
      </div>
    </li>
  );
};

export default FaqItem;
