import React from "react";

const Guide = () => {
  return (
    <>
      <section className="head" id="guide-head">
        <div className="cent">
          <h1>쏘카 이용 안내</h1>
          <h2>
            예약부터 반납까지
            <br className="pbr" /> 앱으로 간편하게 이용하세요.
          </h2>
        </div>
      </section>

      <section className="content" id="guide-cont">
        <div className="wrap">
          <section className="howto">
            <h2 className="sub-title">쏘카 이용방법 알려드려요!</h2>
            <ul className="list-01">
              <li className="mv">
                <h3>
                  <span>1</span> 가입하기
                </h3>
                <p>
                  스마트폰에 쏘카 앱을 다운로드 받고 자동차 운전면허증과
                  결제카드를 등록해 주세요. 쏘카는 만 21세 이상, 운전면허 취득
                  1년 이상부터 이용이 가능합니다.
                </p>
              </li>
              <li className="mv">
                <h3>
                  <span>2</span> 예약하기
                </h3>
                <p>
                  이용 시간을 설정하고 차량이 필요한 장소와 원하는 차종을 선택해
                  주세요. 가까운 쏘카존에서 예약하거나 내 집 앞으로 쏘카를 부를
                  수도 있습니다.
                </p>
              </li>
              <li className="mv">
                <h3>
                  <span>3</span> 차량찾기
                </h3>
                <p>
                  차량이 주차된 쏘카존의 상세 정보를 쏘카 앱에서 확인하세요.
                  쏘카 앱 내 스마트키로 비상등을 켜거나 경적을 울려 쏘카를 보다
                  손쉽게 찾을 수 있습니다.
                </p>
              </li>
              <li className="mv">
                <h3>
                  <span>4</span> 이용하기
                </h3>
                <p>
                  탑승 전 외관, 내부, 기름양 등 차량 상태를 확인해 주세요. 쏘카
                  앱 내 스마트키로 간편하게 차량 문을 열고 닫을 수 있습니다.
                </p>
              </li>
              <li className="mv">
                <h3>
                  <span>5</span> 반납하기
                </h3>
                <p>
                  지정된 장소에 주차 후 차량 내 놓고 내리는 물건은 없는지 확인해
                  주세요. 차 문을 잠근 후 반납하기 버튼을 누르면 이용이
                  완료됩니다.
                </p>
              </li>
            </ul>
          </section>
          <section className="banner-01">
            <div id="blue" className="mvright"></div>
            <h2>
              <span>쏘카 이용 요금</span>도<br /> 확인 해보세요
            </h2>
            <a href="/fare" className="btn btna2 mv3">
              서비스 요금 바로가기
            </a>
          </section>
          <section className="st-02 socar-in-service">
            <ul>
              <li>
                <h3 className="mv">
                  언제 어디서나
                  <br />
                  부르면 쏘카가 오는
                  <br />
                  <span>부름서비스</span>
                </h3>
                <p className="mv">
                  내 근처 주차가 가능한 위치로
                  <br />
                  쏘카를 불러 편하게 이용하세요.
                </p>
              </li>
              <li>
                <h3 className="mv">
                  언제나 부담없이 편하게 이동!
                  <br />
                  <span>패스포트</span>
                </h3>
                <p className="mv">
                  패스포트 하나로
                  <br />
                  쏘카부터 타다까지
                  <br />
                  어떤 상황에서든 편하게 이동하고
                  <br />
                  1년 내내 혜택 받으세요
                </p>
                <a
                  href="https://go.socar.kr/atYTpKm4r5"
                  className="applink btn btnu btna mv3">
                  멤버십 혜택 확인하기
                </a>
              </li>
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default Guide;
