import React from "react";
import { history } from "../../index";
import { corpSocarSite, irSite } from "../../utils/constant";

const Header = () => {
  const path = history.location.pathname;
  const isCompanyPath = path === "/company";

  return (
    <div>
      {!isCompanyPath && (
        <>
          <div id="btn-app" className="">
            <a
              href="https://go.socar.kr/sdhv3ZE4r5"
              target="_blank"
              rel="noopener noreferrer">
              <span className="cent">쏘카 앱 다운로드</span>
              <img
                id="btn-app"
                className="cth"
                src="/images/i-appicon.svg"
                alt=""
              />
              <div id="blue" className="mvright"></div>
            </a>
          </div>
          <header>
            <div id="header-inner" className="ctw">
              <a href="/">
                <img
                  id="logo"
                  className="logo"
                  src="/images/logo-w.svg"
                  alt="logo"
                />
                <img
                  id="logo-blue"
                  className="logo"
                  src="/images/logo-blue.svg"
                  alt="logo"
                />
              </a>
              <div id="hamberger" className="cth">
                <span></span>
                <span></span>
              </div>
              <div id="nav-empty" className="smooth"></div>
              <div id="nav" className="cth">
                <div className="menu-gnb-container">
                  <ul id="menu-gnb" className="menu">
                    <li className="">
                      <a href="guide">이용 안내</a>
                    </li>
                    <li className="">
                      <a href="/fare">요금 안내</a>
                    </li>
                    <li className="">
                      <a href="/benefit">혜택 안내</a>
                    </li>
                    <li className="">
                      <a
                        href="https://modubiz.oopy.io/socar"
                        target="_blank"
                        rel="noopener noreferrer">
                        쏘카존 개설 신청
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a href="/service">쏘카의 서비스</a>
                    </li>
                    <li className="indent">
                      <a
                        href="https://business.socar.kr/"
                        target="_blank"
                        rel="noopener noreferrer">
                        쏘카비즈니스
                      </a>
                    </li>
                    <li className="indent">
                      <a
                        href="https://plan.socar.kr/"
                        target="_blank"
                        rel="noopener noreferrer">
                        쏘카플랜
                      </a>
                    </li>
                    <li className="indent">
                      <a
                        href="https://socar-mkt.socar.kr/event/socarstay"
                        target="_blank"
                        rel="noopener noreferrer">
                        쏘카스테이
                      </a>
                    </li>
                    <li className="indent">
                      <a
                        href="https://fms.socar.kr/"
                        target="_blank"
                        rel="noopener noreferrer">
                        쏘카FMS
                      </a>
                    </li>
                    <hr />
                    <li>
                      <a
                        href={corpSocarSite}
                        target="_blank"
                        rel="noopener noreferrer">
                        회사소개
                      </a>
                    </li>
                    <li>
                      <a
                        href={irSite}
                        target="_blank"
                        rel="noopener noreferrer">
                        공고
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>
        </>
      )}
      {isCompanyPath && (
        <header id="company-header" className="act smooth">
          <div id="header-inner" className="ctw">
            <a href="/">
              <img
                id="logo-blue"
                className="logo"
                src="/images/logo-blue.svg"
                alt="logo"
              />
            </a>
          </div>
        </header>
      )}
    </div>
  );
};

export default Header;
